import { Vue } from "vue-class-component";
import './integral.scss'

export default class VipIntegral extends Vue {
  render(): JSX.Element {
    return <div class="vip_integral">
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">如何注册奖赏计划？</div>
        <p>1.进入道驿空间网站XXXXXX网站地址</p>
        <p>2.点击注册</p>
        <p>3.按照页面显示的简单程序进行注册 完成注册──现在，你可开始赚取积分了！</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">如何赚取积分？</div>
        <p>1.店内购物﹕在道驿空间购物，用餐或者booking课程时，向店员出示你的会员卡或者电话号码</p>
        <p>2.网上购物﹕在道驿空间网站购物或者booking课程时，在付款时登陆会员帐户后就可以直接使用</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">赚取积分攻略</div>
        <p>1.网站消费：$1=1积分</p>
        <p>2.观看课程：1节=50积分</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">积分的用途</div>
        <p>限时积分可兑换现金券或不同的礼品，在道驿空间消费时积分可当作现金使用，每4,000积分=$20，每次签帐最多可抵$400 在门店使用积分时须出示实体卡才可以兑换优惠卷或者礼品</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">兑换现金券</div>
        <p>条件：积分累积至少1000积分可在购物或用餐时使用，每次购物时可使用1张或以上的现金券（积分不能兑换为现金）</p>
        <p>兑换现金券详情：</p>
        <p>-1,000积分兑换HK$5现金券</p>
        <p>-2,000积分兑换HK$10现金券</p>
        <p>-4,000积分兑换HK$20现金券</p>
        <p>-10,000积分兑换HK$50现金券</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">积分分享功能</div>
        <p>分享积分功能，可把积分结予其他会员，最少需分享2,000积分</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">哪里可查阅积分结余？</div>
        <p>1.手机网站──登入手机网站后，可在个人中心页面查看积分结余</p>
        <p>2.网站──登入xxxxxxx网址后，可在个人中心页面查看积分结余</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">积分期限</div>
        <p>1.积分须于三年限期内兑换，逾期作废</p>
        <p>2.假如您连续两年没有赚取或兑换任何积分，系统会默认您无意继续参与奖赏计划并会终止您的账户</p>
        <p>3.在终止账户时，任何未使用的积分将会作废</p>
      </div>
      <div class="vip_integral-list">
        <div class="vip_integral-list-header">现金券券及奖赏</div>
        <p>如何将积分兑换成现金券？</p>
        <p>1.先在道驿空间或网站兑换积分。积分兑换现金券后，顾客会立即收到现金券并可以直接在道驿空间购物或用餐时使用</p>
        <p>2.顾客可以在每次购物时使用一张或以上的现金券</p>
        <p>3.现金券不设找赎，购物金额应尽量高于现金券的面额。如购物金额低于现金券面额，您仍然可以使用现金券付款，但两者差额不能退回</p>
        <p>4.现金券须于兑换后12个月内使用</p>
      </div>
    </div>
  }
}
